import React from "react";
import { withNamespaces } from "react-i18next";

import Form from "../../components/Common/Form";

function Register({ t }) {
  document.body.setAttribute('style', 'background: #f1f5f7!important;')
  return (
    <section className="">
      <Form
        buttonLink="/login"
        buttonText={t("sign_up")}
        account={t("has_account")}
        facebook={t("facebook_signup")}
        google={t("google_signup")}
      />
    </section>
  );
}

export default withNamespaces()(Register);
