import React, { useEffect, useState, useCallback } from 'react';

import { Card, CardBody, Container } from "reactstrap";
import axios from 'axios';
import PropagateLoader from "react-spinners/ScaleLoader";
import { withNamespaces } from 'react-i18next';

import { toast } from 'react-hot-toast';




function Trips({ t }) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchTrips = useCallback(async () => {

    try {
      const { data } = await axios.get(`api/v1/bookings/user-bookings`,);
      if (data?.error) {
        toast.error(data.message);
      } else {

        const values = Object.values(data);
        setData(values[0]);
        console.log(values)

        toast.success(data.message);
        setLoading(false);
      }
    } catch (err) {


      toast.error(err.message);
    }


  }, [])


  useEffect(() => {
    document.body.setAttribute('style', 'background: #f1f5f7!important;')

    fetchTrips()
  }, [fetchTrips]);






  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <PropagateLoader color="#bcc014" width="30px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <>
      <section className="trip-search mb-5 mt-5">
        <div className="container">




          <div className='row'>
            <div className='col-lg-12'>

              {
                data.map((trip) => {

                  return (
                    <Card key={trip.id}>
                      <CardBody>
                        <div className='row p-2'>

                          <div className=" col-lg-1">

                            <div className="text-center">
                              <h5 className="">
                                {trip.tripType}
                              </h5>
                              <small>Type</small>

                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div className="text-center">
                              <h5 className="">
                                {trip.agencyName}
                              </h5>
                              <small>{t('Agency')}</small>

                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="text-center">
                              <h5 className="">
                                {trip.journeyDate}
                              </h5>
                              <small>{t('date')}</small>

                            </div>
                          </div>
                          <div className="col-lg-2">

                            <div className="text-center">
                              <h5 className="">
                                {trip.departureTime}
                              </h5>
                              <small>{t('departure')}</small>
                            </div>
                          </div>
                          <div className="col-lg-2">

                            <div className="text-center">
                              <h5 className="">
                                {trip.ticketNumber}
                              </h5>
                              <small>{t('ticket_number')}</small>
                            </div>
                          </div>

                          <div className=" col-lg-2">

                            <div className="text-center">
                              <h5 className="">
                                {trip.price}
                              </h5>
                              <small>{t('price')}</small>

                            </div>
                          </div>
                          <div className="col-lg-1">

                            <div className="text-center">
                              <h5 className="">
                                {trip.seat}
                              </h5>
                              <small>{t('seat')}</small>

                            </div>
                          </div>


                        </div>
                      </CardBody>
                    </Card>
                  )
                })}
            </div>
          </div>





        </div>

      </section>
    </>
  );
}

export default withNamespaces()(Trips);
