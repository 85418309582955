import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/images/transapp-logo.svg";

import { toast } from "react-hot-toast";
import { useStateMachine } from "little-state-machine";
import { updateUser, updateClient } from "../../utils/updateActions";
import { useForm } from "react-hook-form";


function Form({
  t,
  account,
  buttonText,
  buttonLink,
  source,
}) {
  document.body.setAttribute('style', 'background: #f1f5f7!important;')
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { actions } = useStateMachine({ updateUser, updateClient });
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onSubmitSignup = async (formData) => {
    try {
      setLoading(true);
      const { data } = await axios.post("api/v1/users/signup", formData);
      if (data.error) {
        toast.error(data.error);
        setLoading(false);
      } else {

        toast.success(data.message);

        history.push("/login");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };
  const onSubmitLogin = async (formData) => {
    try {
      setLoading(true);
      const { data } = await axios.post("api/v1/users/login", formData);
      if (data.error) {
        toast.error(data.error);
        setLoading(false);
      } else {
        actions.updateUser({ data });

        localStorage.setItem("authClient", JSON.stringify(data));

        toast.success(data.message);

        history.push("/");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };


  return (
    <div
      className="min-vh-100 d-flex flex-column opacity-mask"
      data-opacity-mask="rgba(0, 0, 0, 0.05)"
    >
      <div className="container my-auto">
        <div className="row">
          <div className="col-md-9 col-lg-7 col-xl-5 mx-auto my-4">
            <div className="panel header_2 center">
              <figure>
                <a href="#0">
                  <img src={logo} width="86" height="80" alt="" />
                </a>
              </figure>

              <form className="input_style_1" onSubmit={source === 'login' ? (handleSubmit(onSubmitLogin)) : (handleSubmit(onSubmitSignup))}
              >

                {/*   <a href="/facebook-login" className="social_bt facebook">
                  {facebook}
                </a>
                <a href="/google-login" className="social_bt google">
                  {google}
  </a>
                <div className="divider">
                  <span>{t("or")}</span>
                </div>*/}
                {source === 'login' ? (null) : (<div className="form-group">
                  <label htmlFor="name">{t("name")}</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    {...register("name", {
                      required: true,

                    })}
                  />
                </div>
                )}
                {errors.name && (
                  <p style={{ color: "red" }}>{t("agency_name_err")}</p>
                )}
                <div className="form-group">
                  <label htmlFor="email_address">{t("email_address")}</label>
                  <input
                    type="email"
                    name="email_address"
                    id="email_address"
                    className="form-control"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                </div>
                {errors.email && (
                  <p style={{ color: "red" }}>{t("email_err")}</p>
                )}
                <label htmlFor="password">{t("password")}</label>
                <div className="row">
                  <div className="input-group mb-3 col-lg-12">

                    <input
                      type={isPasswordVisible ?  "text" : "password"}
                      name="password" id="password"
                      className="form-control"
                      {...register("password", {
                        required: true,
                        minLength: 6,
                      })}

                    />
                    <div className="input-group-prepend bg-white">
                      <span className="input-group-text border-left-0 rounded-right bg-white" id="basic-addon1"><i className={isPasswordVisible ?  "fas fa-eye" : "fas fa-eye-slash"} onClick={togglePassword}></i></span>
                    </div>
                  </div>
                </div>

                {errors.password && (
                  <p style={{ color: "red" }}>{t("password_err")}</p>
                )}

                {buttonLink === '/login' ? (null) : (<p className="float-right mb-3">
                  <Link to="/user-forgot">{t("forgot_password")}?</Link>
                </p>)}

                <button type="submit" className="btn_1 full-width">
                  {buttonText}
                </button>
              </form>
              {source === 'triplogin' ? (null) : source === "login" ? (
                <p className="text-center mt-3 mb-0">
                  {account} <Link to="/register">{t("sign_up")}</Link>
                </p>
              ) : (
                <p className="text-center mt-3 mb-0">
                  {account} <Link to="/login">{t("login")}</Link>
                </p>
              )}
              {source === 'triplogin' ? (<div><div className="divider mt-4">
                <span>{t("or")}</span>


              </div>
                <p className="text-center">
                  No online account? <Link to="/ticket-form">{t("guest_checkout")}</Link>
                </p>
              </div>) : (null)}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Form);
