import React from 'react';
import { NavLink } from 'react-router-dom';

import { withNamespaces} from 'react-i18next';

function Navigation(props) {
    return (
        <>
            <ul>
         
          <NavLink to="/" exact={true} activeClassName="active " className="link-color mr-3" >
            {props.t('home')}
          </NavLink>
               
               
                
             
          <NavLink to="/book" className="link-color" activeClassName="active" >
              {props.t('book_trip')}
            </NavLink>

          
                  
            
              
            </ul>
        </>
    );
}

export default withNamespaces() (Navigation);
