import React from 'react';
import featureImg from '../../assets/images/trans.jpeg';
import featureImg2 from '../../assets/images/sia.jpeg';
import { withNamespaces } from 'react-i18next';



function Features(props) {
  return (
    <>
      <section className="features">
        <div className="container">
          <div className='d-flex flex-column justify-content-center align-items-center text-center'>
            <h2 className='how-h2 mb-4'>
             {props.t('features')}
            </h2>
           

          </div>

          <div className='d-flex flex-column justify-content-center align-items-center text-center'>
            <p className='features-p '>{props.t('features_p')}</p>
          </div>

          <div className="row  pt-5">

            <div className="col-lg-6">
              <div className="post-item-1">
                <img src={featureImg} alt="" />
                <div className="b-post-details">

                  <h2 className='features-h3'>
                    {props.t('gerer')}
                  </h2>
                  <p className='features-pcard'>{props.t('gerer_text')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">

              <div className="post-item-1">
                <img src={featureImg2} alt="" />
                <div className="b-post-details">
                  <h2 className='features-h3'>
                    {props.t('sia')}
                  </h2>
                  <p className='features-pcard'>{props.t('sia_text')}</p>


                </div>
              </div>
            </div>

          </div>

        </div>

      </section>
    </>
  );
}

export default withNamespaces()(Features);
