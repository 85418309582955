import React from "react";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
	component: Component,
	layout: Layout,
  PublicRoutes,
  userPublicRoutes,
  authRoutes,

 
	...rest
}) => (
		<Route
			{...rest}
			render={props => {
       
    
     if (authRoutes && !localStorage.getItem('authClient') ) {
       
					return (
						<Redirect to={{ pathname: "/login"}} />
					);
				}

       
  

      

        
				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

export default AppRoute;

