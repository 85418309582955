import React from "react";
import { withNamespaces } from "react-i18next";

import Form from "../../components/Common/Form";

function Login({ t }) {
  return (
    <section className="mt-5">
      <Form
        buttonLink="/register"
        buttonText={t("login")}
        account={t("no_account_yet")}
        source="login"
        facebook={t("facebook_login")}
        google={t("google_login")}
      />
    </section>
  );
}

export default withNamespaces()(Login);
