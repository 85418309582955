import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

//i18n
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';

// falgs
import usFlag from "../../../assets/images/flags/us.jpg";
import france from "../../../assets/images/flags/french.jpg";

class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      lng: this.props.t('french'),
      flag: france
    };
    this.toggle = this.toggle.bind(this);
    this.changeLanguageAction.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }

  changeLanguageAction = (lng) => {

    //set the selected language to i18n
    i18n.changeLanguage(lng);

    if (lng === "fr")
      this.setState({ lng: this.props.t('french'), flag: france });
    else if (lng === "eng")
      this.setState({ lng: this.props.t('english'), flag: usFlag });
  }

  render() {

    return (
      <React.Fragment>
        <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-none d-sm-inline-block">
          {this.props.user === true ? ( <DropdownToggle tag="button" className="btn header-item2 waves-effect">
            <img className="" src={this.state.flag} alt="Header Language" height="16" />{'  '}
            <span className="align-middle">{this.state.lng}</span>
          </DropdownToggle>):  <DropdownToggle tag="button" className="btn header-item waves-effect">
            <img className="" src={this.state.flag} alt="Header Language" height="16" />{'  '}
            <span className="align-middle">{this.state.lng}</span>
          </DropdownToggle> }
         

          <DropdownMenu right>

            <DropdownItem active={this.state.lng === "English" ? true : false} href="" onClick={() => this.changeLanguageAction('eng')} className="notify-item">
              <img src={usFlag} alt="flag" className="mr-1" height="12" /> <span className="align-middle">{this.props.t('english')}</span>
            </DropdownItem>

            <DropdownItem href="" active={this.state.lng === "French" ? true : false} onClick={() => this.changeLanguageAction('fr')} className="notify-item">
              <img src={france} alt="flag" className="mr-1" height="12" /> <span id="lang-text">{this.props.t('french')}</span>
            </DropdownItem>


          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(LanguageDropdown);
