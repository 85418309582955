import React from 'react';
import aboutImg from '../../assets/images/about_transapp.png';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

function About(props) {
  return (
    <>
      <section className="about-col px-5 ">
        <div className="container">
          <div className="row align-items-center pb-5">
            <div className="col-lg-6 d-none d-lg-block">
              <div className="mr-5">
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src={aboutImg} alt="" />
                </div>

              </div>
            </div>
            <div className="col-lg-6 ">

              <div className="d-flex justify-content-lg-left align-items-lg-left text-lg-left justify-content-center align-items-center text-center" >

                <h2 className='appie-title-2'>{props.t('about_app')}</h2>

              </div>
              <div className='d-flex justify-content-lg-left align-items-lg-left text-lg-left justify-content-center align-items-center text-center'>
                <p className='about-p'>{props.t('about_p')}</p>
              </div>
              <div className=' text-lg-left text-center'>  <Link className="main-btn mt-2" to="/about-transapp">
                {props.t('read_more')}
              </Link></div>
            </div>

          </div>
        </div>

      </section>
    </>
  );
}

export default withNamespaces()(About);
