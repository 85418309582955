import {

  createStore,

} from 'little-state-machine';

createStore(
  {
    trip: { }, // it's an object of your state
    booking: {},
    vehicle:{},
    location:{},
    agency: {},
    user: {},
    parcel: {},
    parcelCount: {},
    search: {},
    client: {},
    bookings: {}
  },
  
);


export function updateAction(state, payload) {
  console.log("state:", state);
  console.log("payload:", payload);
  return {
    ...state,
    trip:{
      ...payload,
    },
   
  };
}

export  function updateBooking(state, payload) {
 
  return {
    ...state,
    
    booking:{
      ...payload,
    }
  };
}
export  function updateVehicle(state, payload) {
 
  return {
    ...state,
    
    vehicle:{
      ...payload,
    }
  };
}

export  function updateLocation(state, payload) {
 
  return {
    ...state,
    
    location:{
      ...payload,
    }
  };
}

export  function updateAgency(state, payload) {
 
  return {
    ...state,
    
    agency:{
      ...payload,
    }
  };
}

export  function updateUser(state, payload) {
 
  return {
    ...state,
    
    user:{
      ...payload,
    }
  };
}

export  function updateClient(state, payload) {
 
  return {
    ...state,
    
    client:{
      ...payload,
    }
  };
}

export  function updateParcel(state, payload) {
 
  return {
    ...state,
    
    parcel:{
      ...payload,
    }
  };
}

export  function updateSearch(state, payload) {
 
  return {
    ...state,
    
    search:{
      ...payload,
    }
  };
}

export  function updateParcelCount(state, payload) {
 
  return {
    ...state,
    
    parcelCount:{
      ...payload,
    }
  };
}

export  function updateBookings(state, payload) {
 
  return {
    ...state,
    
    bookings:{
      ...payload,
 }
  };
}