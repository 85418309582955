import React from "react";

import ForgotPassword from "../../components/Common/ForgotPassword";

function UserForgot() {
 

  return (
   <React.Fragment>
    <ForgotPassword route={'users/user-forgot'}buttonText={'forgot'}/>
   </React.Fragment>
   
  );
}

export default UserForgot;