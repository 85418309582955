import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import useToggle from '../../Hooks/useToggle';
import Header from './Header';
import Footer from './Footer';
import Drawer from './Mobile/Drawer';
import Action from './Action';

function UserLayout(props) {
  const [drawer, drawerAction] = useToggle(false);

  useEffect(() => {
   let currentage = capitalizeFirstLetter(props.location.pathname);

    document.title =
      currentage + "| Book on TransApp";

  }, [props.location.pathname])


  const capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };



  return (<React.Fragment>
    <Drawer drawer={drawer} action={drawerAction.toggle} />
    <Header action={drawerAction.toggle} />

    {props.children}
 <Action/>
  <Footer/>


  </React.Fragment>
  )
}

export default (withRouter(UserLayout));