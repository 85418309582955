import React, { useState } from "react";
import { Button, Row, Col } from "reactstrap";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-GB";
import ptBR from "date-fns/locale/pt-BR";
import { useStateMachine } from "little-state-machine";
import { updateSearch } from "../../utils/updateActions";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import { withNamespaces } from "react-i18next";
import moment from "moment";
import Select from 'react-select';



import {  useForm } from "react-hook-form";

const key = localStorage.getItem('i18nextLng');
function Search({ t, from,  text, setData }) {
  const {
   
    getValues,
    handleSubmit,
  } = useForm();


  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const [depDate, setDepDate] = useState(new Date());
  const fromValue = getValues("from");
  const toValue = getValues("to");
  const dateValue = getValues("journeyDate");
  const { actions, state } = useStateMachine({ updateSearch });
  const [toCity, setToCity] = useState('Douala');
  const [selectedOption, setSelectedOption] = useState('Yaounde');

  let formData = {
    from: selectedOption,
    to: toCity,
    journeyDate: startDate,
    departureTime: depDate,
  }

  let towns = [
    { label: 'Bafoussam', value: 'Bafoussam' },
    { label: 'Bamenda', value: 'Bamenda' },

    { label: 'Buea', value: 'Buea' },
    { label: 'Douala', value: 'Douala' },
    { label: 'Dschang', value: 'Dschang' },
    { label: 'Edea', value: 'Edea' },
    { label: 'Garoua', value: 'Garoua' },
    { label: 'Kribi', value: 'kribi' },
    { label: 'Kumba', value: 'Kumba' },
    { label: 'Limbe', value: 'Limbe' },
    { label: 'Maroua', value: 'Maroua' },
    { label: 'Nkongsamba', value: 'Nkongsamba' },
    { label: 'Yaounde', value: 'Yaounde' },
  ]


  const searchTrip = async () => {
    actions.updateSearch(formData);
    if (formData.from == null || formData.to === null || formData.journeyDate === null || formData.departureTime === null) {
      toast.error('All fields are required')
      return;
    }


    history.push("/trip-search");
  };
  const searchAnotherTrip = async (formData) => {
    actions.updateSearch(formData);
    try {
      const { data } = await axios.get(`api/v1/trips/trip-search`, { params: { from: fromValue, to: toValue, journeyDate: dateValue } });
      if (data?.error) {
        toast.error(data.message);
      } else {

        const values = Object.values(data);
        setData(values[0]);


        //toast.success(data.message);
      }
    } catch (err) {


      toast.error(err.message);
    }




  };


  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : state.isFocused ? '#fff' : '#000',
      backgroundColor: state.isSelected ? "#bcc015" : state.isFocused ? '#bcc015' : '#fff',
      ':active': {
        ...defaultStyles[':active'],
        backgroundColor: state.isSelected
          ? '#162f27'
          : state.isFocused ? '#bcc015'
            : '#162f27'

      },
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#fff",

      borderColor: '#ccc',
      boxShadow: "#bcc015",
      ':hover': {
        ...defaultStyles[':hover'],
        borderColor: state.isSelected
          ? '#162f27'
          : '#bcc015'

      },
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),
  };


  return (
    <form className="input_style_2 d-flex flex-lg-column justify-content-center align-items-center" onSubmit={text === 'book' ? (handleSubmit(searchTrip)) : handleSubmit(searchAnotherTrip)}>

      <Row>
        <Col lg={12} className="mb-4">
          <span>{t('from')}
          </span>
          <Select
            options={towns}

            onChange={setSelectedOption}
            placeholder={selectedOption}
            styles={customStyles}
            isSearchable
            defaultValue={from}
            type="text"
            name="from"
            id="from"
            required

          />
        </Col>

        <Col lg={12} className="mb-4">
          <span>{t('to')}
          </span>
          <Select

            options={towns}

            onChange={setToCity}
            placeholder={toCity}
            styles={customStyles}
            isSearchable
            defaultValue={toCity}
            type="text"
            name="to"

            id="to"
            required

          />
        </Col>
        {text === 'book' ? (
          <Col lg={12} className="mb-4">
            <span>{t('journey_date')}
            </span>
            <DatePicker


              onChange={(date) => setStartDate(date)}
              minDate={moment().toDate()}
              type="date"
              locale={key === 'fr' ? fr : en}
              placeholderText={moment(startDate).format('MM-DD-YYYY')}
             


            />


          </Col>
        ) : (<Col lg={12}>
            <span>{t('journey_date')}
            </span>
            <DatePicker
              onChange={(date) => setStartDate(date)}
              minDate={moment().toDate()}
              locale={key === 'fr' ? fr : en}
              type="date"
              placeholderText={moment(startDate).format('MM-DD-YYYY')}
              className="form-control"
            />
        </Col>)}
        <Col lg={12} className="mb-4">
          <span>{t('time')}
          </span>
          <DatePicker


            onChange={(date) => setDepDate(date)}
            minDate={moment().toDate()}
            placeholderText={moment(depDate, 'hh:mm a').format('HH:mm a')}
            showTimeSelect
           
            showTimeSelectOnly
            timeIntervals={60}
            timeCaption="Time"
            locale={ptBR}
            dateFormat="p"
            timeFormat="p"
            type="time"


          />


        </Col>

        <Col lg={12}>

          <div className="form-group">
            {text === 'book' ? (<Button block type="submit" color="primary">
              {t("search")}
            </Button>) : <Button block type="submit" color="primary">
              {t("modify_search")}
            </Button>}

          </div>
        </Col>
      </Row>
    </form>
  );
}

export default withNamespaces()(Search);
