import React, { useState } from 'react'

import { useHistory } from "react-router-dom";

import axios from "axios";
import { withNamespaces } from "react-i18next";
import logo from "../../assets/images/transapp-logo.svg";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";

function ForgotPassword({ t, route }) {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [password, setPassword] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();



  const onSubmitForgot = async () => {
    try {

      const { data } = await axios.post(`api/v1/${route}`, { email });
      if (data.error) {
        toast.error(data.error);

      } else {
        toast.success(data.message);
       
        setVisible(true);
      }
    } catch (error) {

      toast.error(error.response.data.message);
    }
  };

  const onSubmitReset = async () => {

    try {

     
      const { data } = await axios.post(`api/v1/users/user-reset`, {
        email,
        password,
        resetCode,
      });
      if (data.error) {
        toast.error(data.error);

      } else {
        toast.success(data.message);

        history.push('/login');
      }
    } catch (error) {
       console.log(email)
      toast.error(error.response.data.message);
    }
  };
  return (
    <div
      className="min-vh-100 d-flex flex-column opacity-mask"
      data-opacity-mask="rgba(0, 0, 0, 0.4)"
    >
      <div className="container my-auto">
        <div className="row">
          <div className="col-md-9 col-lg-7 col-xl-5 mx-auto my-4">
            <div className="panel header_2 center">
              <figure>
                <a href="#0">
                  <img src={logo} width="86" height="80" alt="" />
                </a>
              </figure>

              <form
                className="input_style_1"
                onSubmit={!visible ? (handleSubmit(onSubmitForgot)) : (handleSubmit(onSubmitReset))}
              >
                {visible ? (null) : (<div className="form-group">
                  <label htmlFor="email_address">{t("email_address")}</label>
                  <input
                    type="email"
                    name="email_address"
                    id="email_address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"

                  />
                  {errors.email && (
                    <p style={{ color: "red" }}>{t("email_err")}</p>
                  )}
                </div>

                )}

                {visible ? (<>
                  <label htmlFor="password">{t("password")}</label>
                <div class="row">
                  <div class="input-group mb-3 col-lg-12">

                    <input
                      type={isPasswordVisible ?  "text" : "password"}
                      name="password" id="password"
                      className="form-control"
                      onChange={(e) => password(e.target.value)}

                    />
                    <div className="input-group-prepend bg-white">
                      <span className="input-group-text border-left-0 rounded-right bg-white" id="basic-addon1"><i className={isPasswordVisible ?  "fas fa-eye" : "fas fa-eye-slash"} onClick={togglePassword}></i></span>
                    </div>
                  </div>
                </div>
                  
                {errors.password && (
                    <p style={{ color: "red" }}>{t("password_err")}</p>
                  )}
      
    

                <div className="form-group">
                    <label htmlFor="resetCode">{t("resetCode")}</label>
                    <input
                      type="text"
                      name="resetCode"
                      id="resetCode"
                      value={resetCode}
                      onChange={(e) => setResetCode(e.target.value)}
                      className="form-control"

                    />
                    {errors.resetCode && (
                      <p style={{ color: "red" }}>{t("password_err")}</p>
                    )}
                  </div>
                   </>):(null)}
                  
                {!visible ? (
                  <button type="submit" className="btn_1 full-width" >
                    {t("submit")}
                  </button>
                ) : (
                  <button type="submit" className="btn_1 full-width">
                    {t("reset_password")}
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(ForgotPassword);