import React, { useEffect, useState, useRef, useCallback } from 'react';

import { withNamespaces } from "react-i18next";

import { useStateMachine } from "little-state-machine";
import { updateSearch, updateAction, updateBooking } from "../../utils/updateActions";
import { Row, Col, Container, Button } from "reactstrap";
import { io } from 'socket.io-client'
import moment from "moment";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import ClockLoader from "react-spinners/ClockLoader";
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import ReservationModal from '../../components/Modals/ReservationModal';
import SummaryModal from '../../components/Modals/SummaryModal';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'



const ENDPOINT = " https://api.bookontransapp.com";



function SearchResults({ t }) {
  const [, setIsVisible] = useState(false);
  const { actions, state } = useStateMachine({ updateSearch, updateAction, updateBooking });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [working, setWorking] = useState(false);
  const booking ={};
  const tripRef = useRef({});
  const bookingRef = useRef({});
  const [modal, setModal] = useState({
    modal_static: false,
  });

  const [modalSummary, setModalSummary] = useState({
    modal_static: false,
  });

  const [modalConfirm, setModalConfirm] = useState({
    modal_static: false,
  });



  const showModal = () => {
    
    setModal({ modal_static: true });

  };

  const hideModal = () => {

    setModal({ modal_static: false });

  };


  const downloadTicket = () => {

    const input = document.getElementById('ticket-info')
    html2canvas(input, { logging: true, letterRendering: 1, useCORS: true }).then(canvas => {
      let imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        format: "a4",
        unit: "mm",
      });

      pdf.addImage(imgData, "PNG", 0, 0, 210, 297);

      pdf.save("ticket.pdf");
    });

    setModalConfirm({ modal_static: false })
    setWorking(false);

  };




  const getCurrentTrip = (trip) => {
    tripRef.current = trip;
    actions.updateAction({ trip: tripRef.current });
    showModal()


  };




  const showSummary = () => {
    setIsLoading(false);
    setModalSummary({ modal_static: true });
  }



  const journeyDate = moment(state.search.journeyDate).format("MM-DD-YYYY");
  const from = state?.search?.from?.value;
  const to = state?.search?.to?.value;
  const departureTime = moment(state.search.departureTime, 'hh:mm A').format('HH:mm a');

  useEffect(() => {
    

    const socket = io(ENDPOINT);
    socket.on("trip:update", data => {
      getTrips();

    });

    const getTrips = async () => {



      try {
        const { data } = await axios.get(`api/v1/trips/trip-search`, { params: { from: state.search.from, to: state.search.to, journeyDate: journeyDate, departureTime: departureTime } });
        if (data?.error) {
          toast.error(data.message);
        } else {

          const values = Object.values(data);
          setData(values[0]);


        }
      } catch (err) {


        toast.error(err.message);
      }

    }
    // CLEAN UP THE EFFECT
    return () => socket.disconnect();

  }, [journeyDate, state.search.from, state.search.to, departureTime, data])



  const fetchTrips = useCallback(async () => {

    try {
      const { data } = await axios.get(`api/v1/trips/trip-search`, { params: { from: from, to: to, journeyDate: journeyDate, departureTime: departureTime } });
      if (data?.error) {
        toast.error(data.message);
      } else {

        const values = Object.values(data);
        setData(values[0]);
      
        setLoading(false);
      }
    } catch (err) {


      toast.error(err.message);
    }


  }, [from, to, journeyDate, departureTime])


  useEffect(() => {
    document.body.setAttribute('style', 'background: #ffffff !important;')

    fetchTrips()
  }, [fetchTrips]);



  const createBooking = async () => {
    setWorking(true);

    const phone = localStorage.getItem('phone')

    const name = localStorage.getItem("name");
    const idCard = localStorage.getItem("idCard");
    const payerEmail = localStorage.getItem("email");


    const bookingData = {
      phoneNumber: Number(phone),
      name: name,
      idCardNo: idCard,
      payerEmail: payerEmail,
    }

    try {
      if (!state?.user?.data?.data?.email) {
        const { data } = await axios.post(
          `api/v1/bookings/anon-reservation/${state.trip.trip.id}`,
          bookingData
        );

        if (data?.error) {
          toast.error(data.message);
         
          setIsLoading(false);
        } else {


          bookingRef.current = data;
          actions.updateBooking({ booking: bookingRef.current });
         
          localStorage.removeItem('phone')

          localStorage.removeItem("name");
          localStorage.removeItem("idCard");
          localStorage.removeItem("email");
      
          setWorking(false);
          setModalSummary({ modal_static: false });
          setModalConfirm({ modal_static: true });
          hideModal();

          fetchTrips();
        }
      } else {
        const { data } = await axios.post(
          `api/v1/bookings/create-reservation/${state.trip.trip.id}`,
          bookingData
        );

        if (data?.error) {
          toast.error(data.message);
          setWorking(false);
          //setIsLoading(false);
        } else {
          actions.updateBooking({ booking: data });


          localStorage.removeItem('phone')

          localStorage.removeItem("name");
          localStorage.removeItem("idCard");
          localStorage.removeItem("email");
          setWorking(false);
          setModalSummary({ modal_static: false });
          setModalConfirm({ modal_static: true });
          //setIsLoading(false);

        }
      }
    } catch (error) {
      //setIsLoading(false);
      //setIsVisible(true);
      toast.error(error?.response?.data?.message);
      setWorking(false);


    }
  };

  const filterNoSeatsAvailable = data.filter((trip) => {
    return (
      trip.reserved !== trip.seats
    );
  });

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <div className="loader-outer">
            <div className="loader-inner">
              <ClockLoader color="#bcc014" width="50px" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <>
      <section className="trip-search mb-5">
        <div className='container-fluid'>

          <div className='row pt-5'>
            <div className='col-lg-1'>


            </div>
            <div className='col-lg-2'>

              {filterNoSeatsAvailable.length > 1 ? (<h6>{filterNoSeatsAvailable.length} {t('buses')} {t('from')} {from} {t('to')} {to}</h6>) : (<h6>{filterNoSeatsAvailable.length} {t('no_bus')} {t('from')} {from} {t('to')} {to}</h6>)}
            </div>

            <div className='col-lg-6'>


            </div>
          </div>
        </div>
        <Container className="" fluid>
          <Row className=" pt-3 mr-lg-3 d-flex justify-content-lg-center align-items-lg-center  justify-content-center align-items-center text-center">
            {
              data.map((trip) => {
                //const date = moment(trip.departure).format('LT');
                return (

                  <Col lg={5} className="trip-wrap ml-lg-3 mb-4 key={trip.id} " key={trip.id}>

                    <Row className="text-center">
                      <Col md={6} className="mb-3">
                        <span className="price-label">
                          {t('price')}: {trip.price} XAF
                        </span>

                      </Col>
                      <Col md={6} className="mb-3">

                        <h1>{trip.from} - {trip.to}</h1>
                      </Col>
                    </Row>

                    <Row className=" pt-2 text-center pb-5 pr-lg-5 detail-row">
                      <Col md={6} className="mb-3">
                        <img src={trip.agencyLogo} alt='agency logo' className="" style={{ width: '200px', borderRadius: '100px' }} />
                        <h3>{trip.agencyName}</h3>
                      </Col>
                      <Col md={6} className="mb-3">

                        <Row className="gray-background" >
                          <Col md={4} >
                            <span className="gray-background-text">{t('available_seats')}:  {trip.seats - trip.reserved}</span>
                          </Col>
                          <Col md={4} >

                          </Col>
                          <Col md={4}>
                            <span className="gray-background-text">{t('category')}: {trip.tripType}</span>
                          </Col>
                        </Row>
                        <Row className="gray-background mt-4 ">
                         
                          <Col md={4}>
                            <div className="gray-background-text">{t('departure')}</div>
                            <span className="gray-background-text">  {trip.departure}</span>
                          </Col>
                          <Col md={4} >

                          </Col>
                          <Col md={4}>
                            <div className="gray-background-text">{t('arrival')}</div>
                            <span className="gray-background-text">  {trip.arrival}</span>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col md={12}>
                            <div className='text-center'>
                              {trip.reserved !== trip.seats ? (<Button block color={'primary'}
                                onClick={() => {
                                  getCurrentTrip(trip);

                                }}
                              >
                                {t('reserve_ticket')}
                              </Button>) : (<Button block disabled color={'primary'}
                                onClick={() => {
                                  getCurrentTrip(trip);

                                }}
                              >
                                {t('reserve_ticket')}
                              </Button>)}

                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>


                  </Col>
                )
              })}

          </Row>

        </Container>
      </section>
      <ReservationModal
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        getModal={setModal}
        modal={modal}
        booking={booking}
        title={t("book_ticket")}
        showSummary={showSummary}
        
        buttonText={t("next")}
        
      />
        <SummaryModal modalSummary={modalSummary} setModalSummary={setModalSummary} createBooking={createBooking} buttonText={t("book")} working={working} setWorking={setWorking} />
      <ConfirmationModal modalConfirm={modalConfirm} setModalConfirm={setModalConfirm} downloadTicket={downloadTicket}  buttonText={t("download")} />
    </>
  )

}

export default withNamespaces()(SearchResults);