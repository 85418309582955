import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";

// Import Routes
import { publicRoutes, userPublicRoutes, authRoutes} from "./routes/";
import AppRoute from "./routes/route";

// layouts

import UserLayout from "./components/UserLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

//css
import './assets/css/style.css';
import './assets/css/custom.css';

import './assets/css/main.css';
import './assets/css/userstyle.css';


// Import scss
import "./theme.scss";


class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.getLayout = this.getLayout.bind(this);
	}
 
 	/**
	 * Returns the layout
	 */
	getLayout = () => {
		let layoutCls = NonAuthLayout;
		return layoutCls;
	};

	render() {
	//	const Layout = this.getLayout();

		return (
			<React.Fragment>
				<Router>
					<Switch>
          {userPublicRoutes.map((route, idx) => (
							<AppRoute
                exact
								path={route.path}
								layout={UserLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}
            {authRoutes.map((route, idx) => (
							<AppRoute
                exact
								path={route.path}
								layout={UserLayout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))}
						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}


           
					</Switch>
				</Router>
			</React.Fragment>
		);
	}
}




export default App;
