import React from 'react';
import Groupimg from '../../assets/images/how1.svg'
import Groupimgtwo from '../../assets/images/how2.svg'
import Groupimgthree from '../../assets/images/how3.svg'
import { withNamespaces } from 'react-i18next';


function HowItWorks(props) {
  return (
    <>
     
      <section className="how-col pb-5">
       
          <div className='container'>
          <div className='row '>
            <div className='col-lg-12 d-flex flex-column justify-content-center align-items-center text-center'>
              <h2 className='how-h2 mb-4'>{props.t('how_it_works')}

              </h2>
            </div>
            <div className='col-lg-12 d-flex justify-content-lg-center align-items-lg-center  justify-content-center align-items-center text-center'>
              <p className='how-pmain'>{props.t('how_p')}</p>

            </div>




          </div>
          <div className="row mt-5">

            <div className="col-lg-6">
              <div className="text-center">
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src={Groupimg} alt="" />
                </div>

              </div>
            </div>
            <div className="col-lg-6 ">

              <div className="pt-lg-5 d-flex flex-column justify-content-lg-start align-items-lg-start justify-content-center align-items-center">

                <h2 className='how-h2-primary'>
                  {props.t('download_app')}</h2>

                <p className='how-p mb-4'>{props.t('how_download')}</p>

              </div>

            </div>

          </div>
          <div className="row d-flex mt-5">
            <div className="col-lg-6 order-lg-1 order-2">

              <div className="d-flex flex-column justify-content-lg-start align-items-lg-start justify-content-center align-items-center how-wrap">

                <h2 className='how-h2-primary pt-lg-5 how-wrap'>
                 {props.t('book_trip')}</h2>

                <p className='how-p'>{props.t('how_book')}</p>



              </div>

            </div>

            <div className="col-lg-6 order-lg-2 order-1">
              <div className="text-center pt-lg-5">
                <div
                  className="thumb wow animated fadeInUp pt-lg-5"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src={Groupimgtwo} alt="" />
                </div>

              </div>
            </div>


          </div>

         
          <div className="row">
            <div className="col-lg-6 pt-lg-5">
              <div className="text-center pt-lg-5">
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src={Groupimgthree} alt="" />
                </div>

              </div>
            </div>

            <div className="col-lg-6 mt-lg-5 ">

              <div className="d-flex flex-column justify-content-lg-start align-items-lg-start justify-content-center align-items-center pt-lg-5">

                <h2 className='how-h2-primary pt-lg-5'>
                  {props.t('check_in')}</h2>

                <p className='how-p mb-4'>{props.t('how_click')}</p>


              </div>

            </div>



          </div>
        </div>

      </section>
    </>
  );
}

export default withNamespaces() (HowItWorks);
