import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import heroImg from '../../assets/images/hero.png';
import AppleImage from '../../assets/images/App-Store---eng.svg';
import GoogleImage from '../../assets/images/Google-Play---eng.svg';

function Hero({t}) {
    return (
        <>
            <section className="appie-hero-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content">
                               
                   <h1 className="appie-title pt-5">{t('hero_linea')}</h1>

                 <Link to="/book">
                    <h1 className="appie-title">{t('hero_lineb')}</h1>
                 </Link>
                               
                                <ul>
                                    
                    <li className='mb-3'>
                      <img  src={GoogleImage} alt='applestore-link' />
                                    </li>
                    <li className='mb-3'>
                      <img src={AppleImage} alt='applestore-link' />
                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img  src={heroImg} alt="" />
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
               
            </section>
        </>
    );
}
export default withNamespaces()(Hero);

