/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import LanguageDropdown from '../../Common/TopbarDropdown/LanguageDropdown';
import i18n from '../../../i18n';
import ProfileMenu from '../../Common/TopbarDropdown/Profile';
import { useStateMachine } from "little-state-machine";
import { updateClient } from "../../../utils/updateActions";

import logo from "../../../assets/images/transapp-logo.svg";
import { withNamespaces } from 'react-i18next';

const key = localStorage.getItem('i18nextLng')

function Drawer({ drawer, action, t }) {
  const { state } = useStateMachine({ updateClient });
  const [lang, setLang] = useState({ lng: key })
  const [, setModal] = useState({
    modal_static: false,
  });

  const changeLang = () => {
    console.log(key)
    i18n.changeLanguage(key);

    if (key === "fr")
      setLang({ lng: t('french') });
    else if (key === "eng")
      setLang({ lng: t('english') });

  }



  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
      ></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                <div className="canvas_close">
                  <Link to="#" onClick={(e) => action(e)}>
                    <i className="fa fa-times"></i>
                  </Link>
                </div>
                <div className="offcanvas-brand text-center mb-40">
                  <img src={logo} alt="" />
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu d-flex flex-column pt-5">
                    <NavLink to="/" exact={true} activeClassName="active " className="link-color mt-3 " >
                      {t('home')}
                    </NavLink>




                    <NavLink to="/book" className="link-color mt-3" activeClassName="active" >
                      {t('book_trip')}
                    </NavLink>

                    <NavLink to="/book" className="link-color mt-3" activeClassName="active" >
                      {t('about')}
                    </NavLink>

                    <NavLink to="/terms" className="link-color mt-3" activeClassName="active" >
                      {t('terms_of_service')}
                    </NavLink>

                    <NavLink to="/privacy-policy" className="link-color mt-3" activeClassName="active" >
                      {t('privacy_policy')}
                    </NavLink>



                  </ul>

                  {/*<NavLink to="#" className="link-color mt-3" onClick={(e) => action(e) } >
                      {t('language')}
    </NavLink>*/}


                </div>


                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <a href="$">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="$">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>

                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withNamespaces()(Drawer);
