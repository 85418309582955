/* eslint-disable no-unused-vars */
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect} from "react";
import { withNamespaces } from "react-i18next";
import { useStateMachine } from "little-state-machine";
import {
  updateBooking,
  updateAction,
  updateUser,
} from "../../utils/updateActions";
import { useForm } from "react-hook-form";
import { FadeLoader } from "react-spinners";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ReservationModal({
  modal,
  getModal,
  booking,
  t,
  showSummary,
  isLoading,
  setIsLoading,
  buttonText,
}) {
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  
  // eslint-disable-next-line no-unused-vars
  const { state } = useStateMachine({
    updateBooking,
    updateAction,
    updateUser,
  });

 
 const saveLocal = async () =>{
   setIsLoading(true);
   const phone = getValues("phoneNumber");
   const name = getValues('name');
   const idCard = getValues('idCardNo');
   const payerEmail = getValues('payerEmail');

    localStorage.setItem("phoneNumber", phone);
    localStorage.setItem("name", name);
    localStorage.setItem("idCard", idCard);
    localStorage.setItem("email", payerEmail);
 
   getModal({ modal_static: false })
    showSummary();
    
 }

  useEffect(() => {
    reset({
      data: booking,
    });
  }, [booking, reset]);


  
  return (
    <>
      <Modal isOpen={modal.modal_static} backdrop="static" centered size="lg">
        <ModalHeader toggle={() => getModal({ modal_static: false })}>
          Destination: {state?.trip?.trip?.to}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(saveLocal)}>
            <Row>
              <Col lg={6}>
                <div className="form-group">
                  <label htmlFor="name">{t("name")}</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    {...register("name", {
                      required: true,
                      minLength: 6,
                    })}
                  />
                </div>
                {errors.name && (
                  <p style={{ color: "red" }}>{t("agency_branch_err")}</p>
                )}
              </Col>
              <Col lg={6}>
                <div className="form-group">
                  <label htmlFor="idCardNo">{t("id_card")}</label>
                  <input
                    type="text"
                    name="idCardNo"
                    id="idCardNo"
                    className="form-control"
                    {...register("idCardNo", {
                      required: true,
                    })}
                  />
                </div>
                {errors.idCardNo && (
                  <p style={{ color: "red" }}>{t("id_card_err")}</p>
                )}
              </Col>

              <Col lg={6}>
                <div className="form-group">
                  <label htmlFor="phoneNumber">{t("phone")}</label>
                  <input
                    type={"number"}
                    name="phoneNumber"
                    id="phoneNumber"
                    className="form-control"
                    {...register("phoneNumber", {
                      required: true,
                      minLength: 9,
                      maxLength: 12,
                    })}
                  />
                </div>
                {errors.phoneNumber && (
                  <p style={{ color: "red" }}>{t("phone_err")}</p>
                )}
              </Col>

              <Col lg={6}>
                <div className="form-group">
                  <label htmlFor="payerEmail">{t("email_address")}</label>
                  <input
                    type="email"
                    name="payerEmail"
                    id="payerEmail"
                    className="form-control"
                    {...register("payerEmail", {
                      required: true,
                    })}
                  />
                </div>
                {errors.payerEmail && (
                  <p style={{ color: "red" }}>{t("phone_err")}</p>
                )}
              </Col>
            </Row>

            <ModalFooter>
              {isLoading ? (
                <>
                  <FadeLoader
                    color={"#bcc015"}

                    cssOverride={override}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </>
              ) : (
                <>
                  {" "}
                  <Button
                    color="light"
                    onClick={() => getModal({ modal_static: false })}
                  >
                    {t("cancel")}
                  </Button>
                  <Button type="submit" color="primary">
                    {buttonText}
                  </Button>
                </>
              )}
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>

    </>
  );
}

export default withNamespaces()(ReservationModal);
