/* eslint-disable no-unused-vars */
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import { useStateMachine } from "little-state-machine";
import {
  updateBooking,
  updateAction,
  updateUser,
} from "../../utils/updateActions";
import { FadeLoader } from "react-spinners";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ConfirmationModal({
  t,
  downloadTicket,
  working,
  modalConfirm,
  setModalConfirm,
  
  buttonText,
}) {

  const { state } = useStateMachine({
    updateBooking,
    updateAction,
    updateUser,
  });


  

  return (
    <>
      <Modal isOpen={modalConfirm.modal_static} backdrop="static" centered size="md">
        <ModalHeader toggle={() => setModalConfirm({ modal_static: false })}>
          <h2 style={{color: 'green', fontWeight: "bold"}}>{t('book_success')}!</h2>

        </ModalHeader>
        <ModalBody>

          <div className="row">
            <div id="ticket-info" className="col-md-9 col-lg-6 col-xl-5 mx-auto ">

              <ul className="">
                <li>{t('Agency')}: <span>{state?.trip?.trip?.agencyName}</span></li>
                 <li>{t('name')}: <span> {state?.booking?.booking?.data.name}</span></li>
                <li> {t('seat')}: <span> {state?.booking?.booking?.data.seat}</span></li>
                <li>{t('ticket_number')}: <span>{state?.booking?.booking?.data.ticketNumber}</span></li>
                
                <li>{t('to')}: <span>{state?.trip?.trip?.to}</span></li>

                <li>{t('journey_date')}: <span>{
                  state?.trip?.trip?.journeyDate
               } </span></li>

                <li>{t('time')}: <span>{state?.trip?.trip?.departure}</span></li>

                <li>{t('fare')}: <span>{state?.trip?.trip?.price}</span></li>

                {state?.trip?.trip?.price / 100 * 10 > 500 ? (<li>{t('booking_fee')}:  <span>500</span></li>
                ) : < li > {t('booking_fee')} : <span> {state?.trip?.trip?.price / 100 * 10}</span></li>}

                {state?.trip?.trip?.price / 100 * 10 > 500 ? (<li>{t('to_pay')}:  <span>{state?.trip?.trip?.price + 500}</span></li>
                ) : < li > {t('to_pay')} : <span> {state?.trip?.trip?.price / 100 * 10 + state?.trip?.trip?.price}</span></li>}

                


              </ul>

            </div>
          </div>



          <ModalFooter>
            <div className="text-center">
              <p className="summary-p" >Note: {t('reservation_note')}</p>
            </div>
            {working ? (
              <>
                {/* <SpinnerCircular /> <p>{t('loading_message')}...</p>*/}
                <FadeLoader
                  color={"#bcc015"}

                  cssOverride={override}
                  size={150}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </>
            ) : (
              <>
                {" "}
                <Button
                  color="light"
                  onClick={() => setModalConfirm({ modal_static: false })}
                >
                  {t("cancel")}
                </Button>
                <Button type="submit" onClick={downloadTicket} color="primary">
                  {buttonText}
                </Button>
              </>
            )}
          </ModalFooter>

        </ModalBody>
      </Modal>

    </>
  );
}

export default withNamespaces()(ConfirmationModal);
