import React from "react";

import ForgotPassword from "../../components/Common/ForgotPassword";

function ResetPassword({ t }) {
 

  return (
   <React.Fragment>
    <ForgotPassword route={'users/user-reset'}buttonText={'reset'}/>
   </React.Fragment>
   
  );
}

export default ResetPassword;