/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/images/transapp-logo.svg";
import StickyMenu from '../../lib/StickyMenu';
import LanguageDropdown from "../Common/TopbarDropdown/LanguageDropdown";
import { withNamespaces } from 'react-i18next';
import Navigation from '../../routes/Navigation';
import { useStateMachine } from "little-state-machine";
import { updateClient } from "../../utils/updateActions";

import ProfileMenu from '../Common/TopbarDropdown/Profile'

function HomeHeader({ action, t }) {
  const { state } = useStateMachine({ updateClient });
  const [, setModal] = useState({
    modal_static: false,
  });

  useEffect(() => {
    StickyMenu();
  }, []);
  return (
    <React.Fragment>
       <header className="appie-header-area appie-sticky mb-5">
      <div className="container">
        <div className="header-nav-box">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
              <div className="appie-logo-box">
                <a href="/">
                  <img src={logo} alt="" />
                </a>
              </div>
               
            </div>

              <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2 d-flex justify-content-end mx-auto ">
                <div className="appie-header-main-menu">
                  <Navigation />  

                </div>
                
               

              </div>

         
            <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3 d-flex ">



              <div className="appie-btn-box text-right">
                

                  {state?.user?.data?.data?.email === undefined ? (<Link className="btn login-btn ml-3" to="/login">
                    {t('login')}
                  </Link>) : <ProfileMenu source={"user"}
                    logoutRoute={'/api/v1/users/logout'}
                    storageKey={'authClient'}

                    forgot={'/user-forgot'}
                    setModal={setModal}
                  />}

                  <LanguageDropdown
                    user={true}
                  />
             
             
              
                <div
                  onClick={(e) => action(e)}
                  className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                >
                  <i className="fa fa-bars" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
   
    </React.Fragment>
   
  );
}

export default withNamespaces() (HomeHeader);
