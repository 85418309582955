import React from 'react';

/*import BackToTop from '../BackToTop';*/
import Hero from '../../components/PageComponents/Hero';
import About from '../../components/PageComponents/About';
import HowItWorks from '../../components/PageComponents/HowItWorks';
import Features from '../../components/PageComponents/Features';

function Home() {
  document.body.setAttribute('style', 'background: #FFF!important;')
 

  return (
    <>

      <Hero />
      <About />
      <HowItWorks />
      <Features />
    </>
  );
}

export default Home;
