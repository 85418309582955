import React, { useEffect, useState } from 'react';
import { useStateMachine } from "little-state-machine";
import { updateSearch, updateAction } from "../../utils/updateActions";
import { Row, Col, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import axios from 'axios';
import ShortUniqueId from "short-unique-id";
import PropagateLoader from "react-spinners/ScaleLoader";
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast'; 
var Buffer = require("buffer/").Buffer;
const currentYear = new Date().getFullYear();
function setMonth(date) {
  const d = new Date(date);

  return d.toLocaleString("default", { month: "short" });
}

//https://git.heroku.com/transapp-user.git

function PayUnit({ t }) {
  const history = useHistory();
  const transaction = localStorage.getItem('transactionID');
  const [isVisible, setIsVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ticket, setTicket] = useState('');
  const [seat, setSeat] = useState(null);
  const { state } = useStateMachine({ updateSearch, updateAction });

  const [loading, setLoading] = useState(true);
  const PAYUNIT_USERNAME = "4d70631d-397c-4efd-929a-87fcd3a9d162";
  const PAYUNIT_PASSWORD = "2a8f415d-d9d2-4834-a573-b12c1375b94c";

  //const token2 = `${process.env.REACT_APP_PAYUNIT_USERNAME}:${process.env.REACT_APP_PAYUNIT_PASSWORD}`;
  const token = `${PAYUNIT_USERNAME}:${PAYUNIT_PASSWORD}`;
  const encodedToken = Buffer.from(token).toString("base64");

  const [modalSuccess, setModalSuccess] = useState({
    modal_static: false,
  });

  const [modalFailure, setModalFailure] = useState({
    modal_static: false,
  });


  function hideFailureModal() {
    setIsVisible(false);
    setModalFailure({ modal_static: false })
  }

  const hideSuccessModal = () => {
    setSuccess(false);
    setModalSuccess({ modal_static: false });
    history.replace('/book')


  };

  useEffect(  () => {
    setLoading(true);
    const checkPaymentStatus = async () => {

      const t = localStorage.getItem("transactionID", transaction);


      const config = {
        headers: {
          "x-api-key": "sand_oCYvry8nlC13jE8vg8om99clqyHBAe",
          mode: "test",
          "Content-Type": "application/json",
          Authorization: "Basic " + encodedToken,
        },
      };
      try {
        const { data } = await axios.get(
          `https://app.payunit.net/api/gateway/paymentstatus/${t}`,

          config
        );
        if (data?.error) {
          toast.error(data.message);
        } else {
          console.log(data.data.transaction_status)
          return data.data.transaction_status
        }
      }
      catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
      }
    };

   async function checkStatus(){
     function sleep(ms) {
       return new Promise(resolve => setTimeout(resolve, ms));
     }
     let isPending = true;
     let transaction_status = '';
     do {
       //your logic goes here
       transaction_status = await checkPaymentStatus();
       if (transaction_status.toLowerCase() !== 'pending') {
         isPending = false
       }

       await sleep(30000) // every 30 seconds
     } while (isPending)

     if (transaction_status === "SUCCESS") {

       createBooking();
     } else {
       toast.error('Failed to process payment');
       setLoading(false);
     }
   }
    
    const createBooking = async () => {

      const phone = localStorage.getItem('phone')

      const name = localStorage.getItem("name");
      const idCard = localStorage.getItem("idCard");
      const payerEmail = localStorage.getItem("email");


      const tid = new ShortUniqueId({ length: 7, dictionary: "alphanum_upper" });
      const bookingData = {
        phoneNumber: Number(phone),
        numberOfSeats: 1,
        ticketType: "One-way",
        transaction_id: transaction,
        month: setMonth(state.trip.trip.journeyDate),
        year: currentYear,
        week: moment(state.trip.trip.journeyDate).format("W"),
        name: name,
        idCardNo: idCard,
        payerEmail: payerEmail,
        tripId: state.trip.trip.id,
        ticketNumber: tid(),

        seat: null,
        status: "",
        agencyName: state.trip.trip.agencyName,
        agencyId: state.trip.trip.agencyId,
        journeyDate: state.trip.trip.journeyDate,
        price: state.trip.trip.price,
        departureCity: state.trip.trip.from,
        arrivalCity: state.trip.trip.to,
        departureTime: state.trip.trip.departure


      }

      try {
        if (!state?.user?.data?.data?.email) {
          const { data } = await axios.post(
            `api/v1/bookings/anon-booking/${state.trip.trip.id}`,
            bookingData
          );

          if (data?.error) {
            toast.error(data.message);
            setLoading(false);
          } else {
            console.log(data);
            console.log(state);
            setSuccess(true);
            setSeat(data.data.seat);
            setTicket(data.data.ticketNumber);
             localStorage.removeItem('phone')

             localStorage.removeItem("name");
             localStorage.removeItem("idCard");
             localStorage.removeItem("email");
            setLoading(false);
          }
        } else {
          const { data } = await axios.post(
            `api/v1/bookings/create-booking/${state.trip.trip.id}`,
            bookingData
          );

          if (data?.error) {
            toast.error(data.message);
            setLoading(false);
          } else {
            console.log(data);
            console.log(state);
            setSuccess(true);
            setSeat(data.data.seat);
            setTicket(data.data.ticketNumber);
            localStorage.removeItem('phone')

            localStorage.removeItem("name");
            localStorage.removeItem("idCard");
            localStorage.removeItem("email");
            setLoading(false);

          }
        }
      } catch (error) {
        setLoading(false);
        setIsVisible(true);
        toast.error(error.response.data.message);

      }
    };
    checkStatus();
  }, [transaction, state, encodedToken])

 

 
return loading ? (
  <React.Fragment>
    <div className="page-content">
      <Container fluid className="">
        <div className="loader-outer">
          <div className="loader-inner">
            <PropagateLoader color="#bcc014" width="30px" />
          </div>
        </div>
      </Container>
    </div>
  </React.Fragment>
) : (
  <>
    <section className="trip-search mb-5">
      <div className="container pt-5">

        <div className='row'>
          <div className='col-lg-12 text-center'>
           
          </div>
        </div>


       
        {success ? <Modal funk={true} isOpen={modalSuccess} toggle={hideSuccessModal} center size={'sm'}>
          <ModalHeader toggle={hideSuccessModal}>{t('Success')}!</ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={12}>

                <p className='text-center booking-p'>{t('ticket_num')} {ticket}</p>
                <p className='text-center booking-p'>{t('seat')}: {seat}</p>
                  <p className='text-center booking-p'>{t('date')}: {state.trip.trip.journeyDate}</p>
                  <p className='text-center booking-p'>{t('time')}: {state.trip.trip.departure}</p>
                  <p className='text-center booking-p'>{t('to')}: {state.trip.trip.to}</p>
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={hideSuccessModal}>OK</Button>{' '}

          </ModalFooter>
        </Modal> : null}
        {isVisible ? <Modal funk={true} isOpen={modalFailure} toggle={hideFailureModal} center size={'sm'}>
          <ModalHeader toggle={hideFailureModal}>{t('Failed')}!</ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={12}>

                <p className='text-center'>{t('failed_booking')}</p>



              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={hideFailureModal}>OK</Button>{' '}

          </ModalFooter>
        </Modal> : null}


      </div>

    </section>
  </>
);
}

export default withNamespaces()(PayUnit);
